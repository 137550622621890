import React from "react"

import "../css/pages/certifications.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import {Link} from "gatsby";
import Contact from "../containers/index/contact";

const Certifications = () => (
  <Layout>
    <SEO title="Certifications" />
    <div className={"page certifications"}>
        <div className={"header"}>
            <h1 className={"title"}>Certifications</h1>
        </div>

        <section className={"subtitle"}>
            Athies Batiment est certifié RGE. Ainsi, lors de vos travaux vous pouvez bénéficier de plusieurs aides de l'Etat en faisant appel à notre entreprise.<br/>
            Cette page vous permet d'en savoir plus sur ces aides et sur notre rôle.
        </section>

        <section id={"isolation-1-euros"}>
            <h2>Isolation à 1 euros</h2>
            <p>
                Depuis quelques temps, de nombreuses entreprises profitent de ce dispositif de l'Etat pour démarcher les particuliers. Il est important de vérifier si l'entreprise est en capacité de réaliser votre isolation pour 1 euros et si elle est correctement déclarée.
            </p>
            <p>
                Pour qu'une entreprise puisse isoler votre maison pour 1 euros, il faut que celle-ci soit labelisée <Link to={"certifications/#eco-artisan"}>Reconnu garant de l'environnement (RGE)</Link>. C'est le cas de notre entreprise, <b>Athies Batiment</b>. Si l'entreprise ne vous présente pas son attestation, vous pouvez consulter le site <a href={"https://www.faire.fr/trouvez-un-professionnel"}>faire.fr</a> pour vérifier sa situation. Enfin, l'entreprise doit également vous fournir des attestations d'assurances afin d'éviter tout problème en cas de dégats.
            </p>
            <p>
                Si vous souhaitez savoir si vous êtes éligible pour obtenir les aides de l'Etat afin d'isoler votre habitation à 1€, rendez-vous sur le site du gouvernement : <a href={"https://www.ecologique-solidaire.gouv.fr/coup-pouce-economies-denergie-2019-2020"}>cliquez-ici</a>.
            </p>
        </section>

        <section id={"eco-artisan"}>
            <h2>ECO Artisan</h2>
            <p>La qualification ECO Artisans® vous permet de choisir une entrprise artisanale qui respecte plusieurs engagements :</p>

            <ul>
                <li>Athies Batiment s'engage à <b>améliorer la performance énergétique</b> des logements. Nous proposons des évaluations thermique des logements.</li>
                <li>Nous vous proposons des <b>solutions performantes qui répondent à vos attentes</b>. Nos apportons un conseil global en rénovation thermique.</li>
                <li>Nous nous engageons sur la <b>qualité de service et la bonne réalisation</b> de nos travaux.</li>
            </ul>
        </section>

        <section id={"cee"}>
            <h2>Certificat d'Economie d'Energie</h2>
            <p>
                Le Certificat d'Economie d'Energie est un <b>dispositif mis en place par le gouvernement</b> pour réduire la consommation d'énergie de tous les français dans le but de lutter contre le réchauffement climatique. Les fournisseurs d'énergie sont tenus de diminuer leurs empreintes carbones et par ce fait aident les particuliers et entreprises financièrement lors de travaux de rénovation énergétique. Ce processus encourage les français à diminuer leurs consommation d'énergie par le biais de travaux respectueux de l'environnement.
            </p>
            <p>
               Cette <b>aide financière</b> absorbe selon les revenus des particuliers ou des entreprises, <b>environ 40% du montant du total des travaux réalisés</b> en matériaux écologiques dans leurs logements ou locaux. Méconnue des particuliers et des entreprises, elle est <b>cumulable</b> avec d'autres aides qui touchent à l'énergie comme <b>le prêt à taux zéro et le crédit d'impôt à la transition énergétique</b>. Une fois toutes ces aides cumulées leurs montant peuvent <b>couvrir jusqu'à 100% des frais liés aux rénovations.</b>
            </p>

            <p>
                Vous pouvez aussi trouver un article exhaustif sur le Certificat d'Économie d'Énergie et davantage d'informations vis-à-vis des subventions et de leurs éligibilités en cliquant sur ce lien : <a href="https://www.fournisseur-energie.com/actualites/certificats-economies-energie/">https://www.fournisseur-energie.com/actualites/certificats-economies-energie</a>.
            </p>
        </section>

        <Contact/>
    </div>
  </Layout>
)

export default Certifications
